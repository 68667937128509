import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Karriere() {
  return (
    <main className="main-karriere top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Karriere</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead lightgray-bg">
        <h1 className="title darkblue-font titles-margin bolding">
          Bliv en del af <br />
          Provide Business
        </h1>
        <p className="general-content very-darkblue-font content-top-margin">
          Hos Provide Business leder vi altid efter nye, kompetente og
          talentfulde medarbejdere, som ønsker at være en del af vores team.
        </p>
      </section>
      <section className="layout-karriere">
        <div className="title-karriere">
          <h1 className="title orange-font titles-margin bolding">
            Ledige stillinger:
          </h1>
        </div>
        <h2 className="subtitle darkblue-font titles-margin lightbolding">
          Professionel mødebooker
        </h2>
        <p className="sub-subtitle-lead very-darkblue-font">
          Har du erfaring med mødebooking eller anden salgserfaring, og trives
          du IKKE i et traditionelt telemarketingbureau? – Så er denne stilling
          interessant for dig! Læs mere om jobbet her.
        </p>
        <p className="general-content very-darkblue-font">
          Vi er nok det mest utraditionelle telemarketingbureau i Aarhus og
          omegn og har en yderst professionel tilgang til branchen. Vi er et
          modent team, hvor vi arbejder ud fra devisen: “Frihed under ansvar”.
          Vi vægter tillid enormt højt og forventer, at man kan arbejde
          selvstændigt.
          <br />
          <br />
          Vi er en virksomhed i rivende udvikling og søger derfor nye kollegaer
          til vores team. Vi har en lang række spændende kunder og du har her
          mulighed for, at få en unik karrieremulighed med store
          udviklingsmuligheder.
          <br />
          <br />
          Du vil få ansvaret for din egen kundeportefølje og pleje heraf. Vi
          tilrettelægger i fællesskab dine fremtidige projekter og vi lægger
          stor vægt på diversistet i opgaverne, så ingen dage bliver 100% ens.
        </p>
        <h3 className="title-list-karriere">
          Du er helt perfekt til stillinger hvis du...
        </h3>
        <div className="cont-list-karriere">
          <ul style={{ listStyleType: "disc" }}>
            <li className="general-content very-darkblue-font">
              Har erfaring som mødebooker indenfor B2B
            </li>
            <li className="general-content very-darkblue-font">
              Har kendskab til erhvervslivets ageren
            </li>
            <li className="general-content very-darkblue-font">
              Er opsøgende, nysgerrig og har et positivt sind
            </li>
            <li className="general-content very-darkblue-font">
              Er frisk, mødestabil og god til at skabe relationer
            </li>
            <li className="general-content very-darkblue-font">
              Kan smile igennem telefonen
            </li>
            <li className="general-content very-darkblue-font">
              Er pligtopfyldende og selvstændig i dit arbejde
            </li>
            <li className="general-content very-darkblue-font">
              Kan arbejde struktureret og professionelt
            </li>
            <li className="general-content very-darkblue-font">
              Brænder for succes og resultater
            </li>
          </ul>
        </div>
        <h3 className="title-list-karriere">Vi tilbyder til gengæld...</h3>
        <div className="cont-list-karriere">
          <ul style={{ listStyleType: "disc" }}>
            <li className="general-content very-darkblue-font">
              En spændende stilling med mulighed for deltid eller fuldtid
            </li>
            <li className="general-content very-darkblue-font">
              Fast grundløn og gode ansættelsesvilkår
            </li>
            <li className="general-content very-darkblue-font">
              Opstart snarest muligt
            </li>
          </ul>
        </div>
        <h3 className="title-list-karriere">Arbejdsopgaver:</h3>
        <div className="cont-list-karriere">
          <ul style={{ listStyleType: "disc" }}>
            <li className="general-content very-darkblue-font">
              Din hovedopgave vil være mødebooking
            </li>
            <li className="general-content very-darkblue-font">
              Du vil have account- og budgetansvar for udvalgte kunder
            </li>
            <li className="general-content very-darkblue-font">
              Daglig/ugentlig dialog med dine faste kunder
            </li>
          </ul>
        </div>
      </section>
      <section className="layout-karriere">
        <p className="general-content very-darkblue-font">
          <strong className="lightbolding">Vil du vide mere?</strong> Du er
          velkommen til at kontakte vores adm. direktør Anette Foss Jensen på
          telefon 70 3456 11 eller sende os en ansøgning pr. mail{" "}
          <strong className="lightbolding">job@providebusiness.dk</strong>.
          <br />
          <br />
          Du kan læse yderligere om os på{" "}
          <strong className="lightbolding">www.providebusiness.dk</strong>. Vi
          glæder os til at høre fra dig.
        </p>
      </section>
    </main>
  );
}
