import iconteam from "../icons/team-static.png";
import SalgsteamData from "../data/SalgsteamData.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Salgsteam() {
  return (
    <main className="top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Salgsteam</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead orange-bg" id="display-card-team">
        <img src={iconteam} alt="team icon" className="icon-team"></img>
        <h1 className="title darkblue-font titles-margin bolding">
          Team bag Provide Business
        </h1>
        <p className="general-content very-darkblue-font content-top-margin">
          Vores mødebookere er alle erfarne profiler med stærke kompetencer
          inden for telemarketing og salgsarbejde.
        </p>
      </section>
      <section>
        <SalgsteamData />
      </section>
    </main>
  );
}
