import suitcaseicon from "../icons/education-static.png";
import icon4 from "../icons/corner-line-white.png";
import icon2 from "../icons/corner-line-orange.png";
import leadgenereringbooker from "../img/leadgenerering-booker.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Leadgenerering() {
  return (
    <main className="main-desktop-lead top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Leadgenerering</title>
        </Helmet>
      </HelmetProvider>
      <section className="lightgray-bg card card-lead" id="higher-depth">
        <img
          src={suitcaseicon}
          alt="leadgenerering icon"
          className="icon1-lead"
        ></img>
        <h1 className="title darkblue-font titles-margin bolding">
          Leadgenerering
        </h1>
        <p className="general-content very-darkblue-font content-top-margin">
          Ved leadgenerering er kunsten at finde de rigtige kunder – og bringe
          dem til dig. Du får hos Provide Business kvalificeret hjælp til at
          tiltrække nye relevante kunder og udvide din kundeportefølje samt dine
          salgsaktiviteter.
        </p>
      </section>
      <section className="desktop-layout-lead">
        <section className="card orange-bg card-lead" id="lower-depth">
          <div className="titles-margin">
            <h1 className="title darkblue-font margin-bottom-title bolding">
              Få en brandvarm
            </h1>
            <h2 className="subtitle white-font lightbolding">
              kundeporteføjle
            </h2>
          </div>
          <p className="sub-subtitle-lead">
            Kunderne er fundamentet for alt andet i virksomheden. Ingen kunder –
            ingen virksomhed
          </p>
          <p className="general-content very-darkblue-font">
            Leadgenerering inden for B2B er en proces, hvor der indsamles
            oplysninger på potentielle kunder, der har vist interesse i din
            virksomhed.
            <br />
            <br />
            Disse leads er uundværlige for de fleste virksomheder – men de kan
            være ressourcekrævende på alle fronter. Én ting er at samle en masse
            leads – men det handler om kvalitet fremfor kvantitet.
            <br />
            <br />
            Telemarketing er et velafprøvet redskab til at komme i dialog med
            potentielle kunder, og vores erfaring og viden inden for branchen
            sikrer dig høj kvalitet af leads.
          </p>
          <img
            src={icon4}
            alt="corner lines"
            className="forside-icon-3 desktop-icon-size"
          ></img>
        </section>
        <section className="card darkblue-bg card-lead" id="layout-grid-lead">
          <img
            src={icon2}
            alt="corner lines"
            className="forside-icon-1 desktop-icon-size"
          ></img>
          <h1 className="title white-font titles-margin">
            Vi kører altid en <br />
            testperiode for at komme dine forretningsmål i møde
          </h1>
          <p className="general-content white-font">
            Provide Business går nemlig aldrig på kompromis med kvaliteten.
            Hvorfor? <br />
            Fordi succesfuld mødebooking indebærer, at den både skal give kunden
            lyst til at høre mere om dine produkter – og til at købe dem!
          </p>
        </section>
      </section>
      <section className="desktop-layout-lead">
        <section className="lead-booker-img-cont">
          <img
            src={leadgenereringbooker}
            alt="Booker"
            className="img-lead"
          ></img>
          <div className="color-bg"></div>
        </section>
        <section className="card card-lead lightgray-bg" id="layout-grid-lead">
          <h1 className="title darkblue-font titles-margin lightbolding">
            Vi arbejder med respekt og dialog
          </h1>
          <p className="general-content very-darkblue-font line-height-content">
            Med effektiv og fokuseret leadgenerering kan du øge din omsætning,
            men det kræver den rette viden og de rigtige værktøjer at opnå
            optimal og frugtbar leadgenerering. Lad Provide Business varetage
            opfølgning og kontakt til kunder, så frigiver I betydelig mere
            energi til virksomheden. Vores leadgenering-team har en bred
            erfaring inden for en lang række brancher. Vi håndterer
            kundekontakten med respekt og professionalisme og sikrer os i
            processen at bevare den gode dialog.
          </p>
        </section>
      </section>
    </main>
  );
}
