import emailjs from "emailjs-com";
import React, { useState, useRef } from "react";
import "../css/ContactForm.css";

export default function ContactForm() {
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n17sact",
        "template_yrsvysh",
        form.current,
        "1dO27R30_9Br7gllT"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowPopup(true);
          setMessage("Mail sendt! Vi kommer tilbage til dig så hurtigt som muligt!");
          setTimeout(() => {
            setShowPopup(false);
            setMessage("");
          }, 5000);
        },
        (error) => {
          console.log(error.text);
          setMessage("Der opstod en fejl. Prøv igen senere.");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      );
    form.current.reset();
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="form-group">
          <input className="input-contact-form" type="text" name="virksomhed" placeholder="Virksomhed*" required />
        </div>
        <div className="form-group">
          <input className="input-contact-form" type="text" name="fuldnavn" placeholder="Fuld navn*" required />
        </div>
        <div className="form-group">
          <input className="input-contact-form" type="phonenumber" name="telefon" placeholder="Arbejdstlf.*" required />
        </div>
        <div className="form-group">
          <input className="input-contact-form" type="text" name="email" placeholder="Email*" required />
        </div>
        <div className="form-group">
          <textarea className="input-contact-form" name="emne" placeholder="Dit emne" />
        </div>
        <input type="submit" value="Send formular" />
      </form>
      {message && (
        <div className={`popup ${showPopup ? "show" : ""}`}>
          <p className="popup-message">{message}</p>
        </div>
      )}
    </div>
  );
}
