import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import marketingicon from "../icons/marketing-static.png";
import seoicon from "../icons/seo.png";
import semicon from "../icons/sem.png";
import smaicon from "../icons/sma.png";

export default function Marketing() {
  return (
    <main className="main-desktop-lead top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Marketingstrategi</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead lightgray-bg padding-card-marketing">
        <img
          src={marketingicon}
          alt="marketing icon"
          className="marketing-icon"
        ></img>
        <h1 className="title darkblue-font titles-margin bolding">
          Marketingstrategi
        </h1>
        <p className="general-content very-darkblue-font line-height-marketing">
          En marketingstrategi er en omfattende plan for at nå en virksomheds
          marketingmål. Det involverer en række aktiviteter, herunder
          markedsundersøgelser, målgruppeidentifikation, produktudvikling,
          prisfastsættelse, promovering og distribution.
          <br />
          Nøglekomponenterne i en marketingstrategi består af 8 punkter:
        </p>
        <section className="display-list-marketing">
          <div className="list-marketing-desktop">
            <ol style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Markedsundersøgelse:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Udførelse af markedsundersøgelser for at få indsigt i
                  målgruppen, branchetendenser og konkurrenter.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Målgruppeidentifikation:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Definition af målgruppen baseret på faktorer som alder, køn,
                  placering, interesser og købsadfærd.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Produktudvikling:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Udvikling af produkter eller tjenester, der opfylder
                  målgruppens behov og ønsker.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Prisstrategi:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Fastsættelse af priser, der er konkurrencedygtige og afspejler
                  værdien af produktet eller tjenesten.
                </p>
                <br />
              </li>
            </ol>
          </div>
          <div className="list-marketing-desktop">
            <ol start="5" style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Promoveringsstrategi:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Udvikling af en plan for promovering af produktet eller
                  tjenesten til målgruppen gennem annoncering, public relations
                  og andre marketingkanaler.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Distributionsstrategi:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Beslutning om, hvordan produktet eller tjenesten skal
                  distribueres til målgruppen.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Budgettering:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Tildeling af et budget til marketingaktiviteter og overvågning
                  af investeringsafkastet.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title very-darkblue-font bolding">
                  Evaluering og optimering:
                </h1>
                <p className="list-content very-darkblue-font line-height-marketing">
                  Løbende evaluere effektiviteten af marketingstrategien og
                  foretage justeringer for at optimere resultaterne.
                </p>
                <br />
              </li>
            </ol>
          </div>
        </section>
        <p className="general-content very-darkblue-font line-height-marketing">
          En succesfuld marketingstrategi kræver omhyggelig planlægning,
          udførelse og overvågning for at opnå de ønskede resultater. Her vil
          Provide Business kunne hjælpe hele vejen igennem, og være din faste
          sparringspartner.{" "}
        </p>
      </section>
      <section className="text-marketing padding-card-marketing">
        <p className="general-content very-darkblue-font bolding line-height-marketing">
          Det er tre ord man ofte støder på, hvis – og når – man vil vide mere
          om markedsføring. Den ene er optimering af websteder, den anden er
          betalt markedsføring og den tredje omhandler markedsføring på de
          sociale medier. Selvom der er forskel på dem, hænger de unægtelig
          sammen.{" "}
        </p>
      </section>
      <section className="card card-lead darkblue-bg higher-dropshadow padding-card-marketing">
        <img src={seoicon} alt="SEO icon" className="icons-marketing"></img>
        <h1 className="title white-font titles-margin">
          Search Engine
          <br />
          Optimisation
        </h1>
        <p className="general-content white-font line-height-marketing">
          <strong className="bolding">SEO</strong>, eller søgemaskineoptimering
          (Search Engine Optimization) er processen med at optimere et websted
          og dets indhold for at forbedre dets synlighed og placering på
          søgemaskineresultatsider (SERP'er). Målet med SEO er at øge den
          organiske, ikke-betalte trafik til en hjemmeside ved at gøre det
          nemmere for søgemaskiner at forstå hjemmesidens indhold og relevans
          for brugernes søgeforespørgsler. SEO er en kompleks og konstant
          foranderlig proces, der kræver løbende overvågning, analyse og
          optimering. Succesfuld SEO kræver en kombination af tekniske,
          analytiske og kreative færdigheder samt en dyb forståelse af
          søgemaskinealgoritmer og brugeradfærd.
        </p>
        <p className="general-content orange-font bolding content-margin-marketing">
          Et par af nøglekomponenterne i SEO som Provide Business kan hjælpe
          med:
        </p>
        <section className="display-list-marketing list-marketing-desktop-right">
          <div className="list-marketing-desktop">
            <ol style={{ listStyleType: "decimal" }}>
              <li className="general-content white-font">
                <h1 className="list-title lightbolding">Søgeordsforskning:</h1>
                <p className="list-content line-height-marketing">
                  Identificering og udvælgelse af relevante søgeord, som brugere
                  kan bruge til at søge efter indhold relateret til webstedet.
                </p>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title lightbolding">On-page optimering:</h1>
                <p className="list-content line-height-marketing">
                  Optimering af webstedsindhold, såsom overskrifter,
                  metabeskrivelser og brødtekst, for at inkludere relevante
                  søgeord og forbedre læsbarheden og brugeroplevelsen.
                </p>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title font lightbolding">
                  Teknisk optimering:
                </h1>
                <p className="list-content line-height-marketing">
                  Sikring af, at hjemmesiden er tilgængelig for søgemaskiner,
                  har en klar sitestruktur, er mobilvenlig og har hurtige
                  sideindlæsningstider.
                </p>
                <br />
              </li>
            </ol>
          </div>
          <div className="list-marketing-desktop">
            <ol start="4" style={{ listStyleType: "decimal" }}>
              <li className="general-content white-font">
                <h1 className="list-title lightbolding">Linkbuilding:</h1>
                <p className="list-content line-height-marketing">
                  Opbygning af indgående links af høj kvalitet fra andre
                  hjemmesider for at forbedre hjemmesidens autoritet og
                  troværdighed.
                </p>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title lightbolding">Indholdsskabelse:</h1>
                <p className="list-content line-height-marketing">
                  Oprettelse af relevant, og engagerende indhold af høj
                  kvalitet, der tilfredsstiller brugerens hensigt og giver værdi
                  for brugerne.
                </p>
                <br />
              </li>
            </ol>
          </div>
        </section>
      </section>
      <section className="card card-lead lightgray-bg secondlevel-dropshadow padding-card-marketing">
        <img src={semicon} alt="SEM icon" className="icons-marketing"></img>
        <h1 className="title darkblue-font titles-margin">
          Search Engine
          <br />
          Marketing
        </h1>
        <p className="general-content very-darkblue-font line-height-marketing">
          <strong className="bolding">SEM</strong>, eller
          søgemaskinemarkedsføring (Search Engine Marketing), er en form for
          online markedsføring, der involverer promovering af websteder ved at
          øge deres synlighed på søgemaskineresultatsider (SERP'er) gennem
          betalt annoncering. SEM involverer at bruge betalte søgeannoncer, også
          kendt som pay-per-click (PPC) annoncering, for at nå ud til
          potentielle kunder, der aktivt søger efter produkter eller tjenester
          relateret til en virksomhed. SEM kan være en yderst effektiv måde at
          nå ud til potentielle kunder og drive websitetrafik, især for
          virksomheder i konkurrenceprægede industrier. Men succesfulde
          SEM-kampagner kræver omhyggelig planlægning, styring og løbende
          optimering for at opnå de ønskede resultater, og her vil Provide
          Business være dig behjælpelig, ved at være din samarbejdspartner hele
          vejen igennem.
        </p>
        <p className="general-content orange-font bolding content-margin-marketing">
          Her er nogle nøglekomponenter i SEM:
        </p>
        <section className="display-list-marketing list-marketing-desktop-right">
          <div className="list-marketing-desktop">
            <ol style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Søgeordsforskning:</h1>
                <p className="list-content line-height-marketing">
                  Identificering og udvælgelse af relevante søgeord, som brugere
                  kan bruge til at søge efter indhold relateret til webstedet.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Annonceoprettelse: </h1>
                <p className="list-content line-height-marketing">
                  Oprettelse af overbevisende og relevant annoncetekst, der
                  inkluderer målrettede søgeord og tilskynder brugerne til at
                  klikke på annoncen.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title font lightbolding">
                  Landingssideoptimering:
                </h1>
                <p className="list-content line-height-marketing">
                  Design og optimering af landingssider for at give en
                  problemfri og relevant brugeroplevelse og øge sandsynligheden
                  for konvertering.
                </p>
                <br />
              </li>
            </ol>
          </div>
          <div  className="list-marketing-desktop">
            <ol start="4" style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Budstyring:</h1>
                <p className="list-content line-height-marketing">
                  Håndtering af bud for annonceplacering i søgeresultater og
                  optimering af annonceudgifter for at opnå maksimalt
                  investeringsafkast (ROI).
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">
                  Effektivitetssporing:{" "}
                </h1>
                <p className="list-content line-height-marketing">
                  Overvågning og analyse af nøgleeffektivitetsmetrics såsom
                  klikrate (Click-Through-rate - CTR), konverteringsrate og pris
                  pr. konvertering for at optimere kampagnens ydeevne.
                </p>
                <br />
              </li>
            </ol>
          </div>
        </section>
      </section>
      <section className="card card-lead orange-bg thirdlevel-dropshadow padding-card-marketing">
        <img src={smaicon} alt="SMA icon" className="icons-marketing"></img>
        <h1 className="title darkblue-font titles-margin">
          Social Media
          <br />
          Advertising
        </h1>
        <p className="general-content very-darkblue-font line-height-marketing">
          <strong className="bolding">SMA</strong>, eller sociale medier
          markedsføring (Social Media Advertising), er en form for
          onlineannoncering, der involverer promovering af produkter, tjenester
          eller brands på sociale medieplatforme gennem betalte annoncer.
          Sociale medieplatforme såsom Facebook, Instagram, Twitter og LinkedIn
          tilbyder en række forskellige annonceformater, herunder sponsorerede
          indlæg, displayannoncer, videoannoncer og mere. SMA kan være en yderst
          effektiv måde at nå ud til og interagere med potentielle kunder på
          sociale medieplatforme. Men succesfulde SMA-kampagner kræver
          omhyggelig planlægning, styring og løbende optimering for at opnå de
          ønskede resultater. Det er vigtigt at udvikle en strategi, der stemmer
          overens med de forretningsmæssige mål, og skabe indhold af høj
          kvalitet, der resonerer hos målgruppen for at øge engagement og
          konverteringer.
        </p>
        <p className="general-content very-darkblue-font bolding content-margin-marketing">
          Nøglekomponenterne i SMA som Provide Business kan være behjælpelige
          med:
        </p>
        <section className="display-list-marketing list-marketing-desktop-right">
          <div className="list-marketing-desktop">
            <ol style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">
                  Målgruppeidentifikation:{" "}
                </h1>
                <p className="list-content line-height-marketing">
                  Identifikation af målgruppen baseret på faktorer som alder,
                  køn, placering, interesser og adfærd.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Annonceoprettelse: </h1>
                <p className="list-content line-height-marketing">
                  Oprettelse af overbevisende og visuelt tiltalende
                  annonceindhold, der resonerer med målgruppen og tilskynder til
                  engagement og handling.
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title font lightbolding">
                  Annoncemålretning:
                </h1>
                <p className="list-content line-height-marketing">
                  Indstilling af målretningsparametre baseret på faktorer som
                  demografi, interesser, adfærd og geografisk placering for at
                  sikre, at annoncer når ud til den ønskede målgruppe.
                </p>
                <br />
              </li>
            </ol>
          </div>
          <div className="list-marketing-desktop">
            <ol start="4" style={{ listStyleType: "decimal" }}>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Budstyring:</h1>
                <p className="list-content line-height-marketing">
                  Håndtering af bud for annonceplacering i søgeresultater og
                  optimering af annonceudgifter for at opnå maksimalt
                  investeringsafkast (ROI).
                </p>
                <br />
              </li>
              <li className="general-content very-darkblue-font">
                <h1 className="list-title lightbolding">Præstationssporing:</h1>
                <p className="list-content line-height-marketing">
                  Overvågning og analyse af nøgleeffektivitetsmålinger såsom
                  engagementsrate, klikrate (CTR), konverteringsrate og pris pr.
                  konvertering for at optimere kampagnens ydeevne.
                </p>
                <br />
              </li>
            </ol>
          </div>
        </section>
      </section>
      <section className="card card-lead lightgray-bg lower-dropshadow">
        <p className="general-content very-darkblue-font lightbolding line-height-marketing">
          Uanset hvor din virksomhed mangler sparring, hjælp eller inspiration.
          <br />
          Provide Business står klar som din samarbejdspartner.
        </p>
        <button className="buttons-forside">
          <Link to="/kontakt" className="font-buttons-forside">
            Kontakt os
          </Link>
        </button>
      </section>
    </main>
  );
}
