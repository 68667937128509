import iconbooking from "../icons/booking-static.png";
import meetingoffice from "../img/meeting-office.jpg";
import anetteoffice from "../img/anette-office.jpg";
import iconcorner1 from "../icons/corner-dots-orange.png";
import iconcorner2 from "../icons/corner-dots-white.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Mødebooking() {
  return (
    <main className="top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Mødebooking</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead lightgray-bg desktop-layout-booking-cont">
        <img
          src={iconbooking}
          alt="icon-mødebooking"
          className="icon-booking"
        ></img>
        <h1 className="title darkblue-font titles-margin lightbolding">
          Mødebooking
        </h1>
        <p className="general-content very-darkblue-font line-height-content">
          Mødebooking er en vigtig del af en effektiv markedsføringsstrategi, og
          er en meget effektiv måde at skaffe nye kunder på, og dermed øge
          omsætningen. Mødebooking giver mulighed for at nå ud til kunderne på
          en personlig og direkte måde, og det indebærer at vi på vegne af vores
          kunder kontakter potentielle kunder for at arrangerer et møde eller en
          samtale.
          <br /> Vores kunder kan så efterfølgende tage ud på mødet og diskutere
          behov og præsentere deres produkter eller tjenester. <br />
          Ved at lade Provide Business arrangerer et møde eller en samtale, kan
          I som kunde forklare jeres produkter eller tjenester mere detaljeret
          og besvare eventuelle spørgsmål eller bekymringer, som kunden måtte
          have, uden at skulle bruge tid på at få mødet i hus.
        </p>
      </section>
      <section className="content-mødebooking-cont">
        <section className="card-booking-cont desktop-layout-booking-cont margin-cont-booking">
          <section className="box-shadow-right">
            <img
              src={meetingoffice}
              alt="Provide Business meeting"
              className="office-booking"
            ></img>
          </section>
          <section className="card card-lead darkblue-bg card-with-bg-icon higher-dropshadow layout-card-content">
            <img
              src={iconcorner1}
              alt="icon"
              className="forside-icon-1 desktop-icon-size"
            ></img>
            <h1 className="title white-font lightbolding titles-margin">
              Opbyg bedre relationer med mødebooking
            </h1>
            <p className="general-content white-font line-height-content">
              Mødebooking har hjulpet vores kunder med at opbygge langsigtede
              relationer til deres kunder og øge kundeloyaliteten. Ved hjælp af
              mødebooking hos Provide Business, har vi givet vores kunder en
              unik mulighed for at mødes ansigt til ansigt med deres potentielle
              kunder. <br />
              <br />
              Provide Business udformer altid en udførlig oversigt over de leads
              vi har kontaktet, vi giver altid en fyldestgørende beskrivelse af
              de problemer den potentielle kunder står over for, og på den måde
              bruger vores kunder mødebooking til bedre at forstå deres egne
              kunders behov og forventninger og tilbyde løsninger, der er
              skræddersyet til deres specifikke behov.
            </p>
          </section>
        </section>
        <section
          className="card card-lead lightgray-bg secondlevel-dropshadow"
          id="margin-booking"
        >
          <h1 className="title orange-font titles-margin bolding">
            Vi kører altid en testperiod for at komme dine forretningsmål i møde
          </h1>
          <p className="general-content very-darkblue-font">
            Provide Business går nemlig aldrig på kompromis med kvaliteten.
            <br />
            <strong className="lightbolding">Hvorfor?</strong>
            <br /> Fordi succesfuld mødebooking indebærer, at den både skal give
            kunden lyst til at høre mere om dine produkter – og til at købe dem!
          </p>
        </section>
        <section className="card-booking-cont-2 margin-cont-booking">
          <section className="box-shadow-left" id="grid-booking-1">
            <img
              src={anetteoffice}
              alt="Anette Foss Jensen"
              className="anette-booking"
            ></img>
          </section>
          <section
            className="card card-lead orange-bg lower-dropshadow card-with-bg-icon layout-card-content"
            id="grid-booking-2"
          >
            <h1 className="title darkblue-font titles-margin lightbolding">
              Eksperter indenfor mødebooking
            </h1>
            <p className="general-content very-darkblue-font">
              Mødebooking kræver dog en grundig planlægning og ekspertise for at
              opnå succes. Vi hos Provide Business er vi specialister i at
              identificere de kunder, der er mest tilbøjelige til at have
              interesse i vores kunders produkter eller tjenester, og derudfra
              udvikle en overbevisende og effektiv salgs- eller
              markedsføringsmeddelelse, der kan overbevise kunden om at mødes
              med jer. <br/><br/>For at maksimere succesen med mødebooking har vores
              kunder outsourcet opgaven til Provide Business, der har erfaring
              med at arrangere og planlægge møder for B2B-virksomheder. Dette
              har hjulpet vores kunder med at frigøre interne ressourcer og
              sikre, at mødebooking er udført professionelt og effektivt.<br/><br/> I alt
              kan mødebooking være en effektiv måde for virksomheder at skaffe
              nye kunder, øge salget og opbygge langsigtede relationer med deres
              kunder. Det kræver dog en grundig planlægning og ekspertise for at
              opnå succes, og vi Provide Business har mange års erfaring, og kan
              planlægge netop den kampagne der lever op til jeres krav.
            </p>
            <img
              src={iconcorner2}
              alt="icon"
              className="forside-icon-3 desktop-icon-size"
            ></img>
          </section>
        </section>
      </section>
    </main>
  );
}
