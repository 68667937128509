import ReviewsSlider from "../data/ReviewsSlider";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function VoresCases() {
  return (
    <main className="layout-vorescases top-content-margin">
            <HelmetProvider>
        <Helmet>
          <title>Provide Business | Vores Cases</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead lightgray-bg cases-desktop-layout">
        <h1 className="title orange-font titles-margin lightbolding" id="title-cases">
          Skab resultater med Provide Business
        </h1>
        <p className="general-content very-darkblue-font content-top-margin">
          Vi hjælper virksomheder med at få skabt professionelle og langsigtede
          kunderelationer. Vi samarbejder med virksomheder der ønsker at opnå
          sine salgsmål- og mere til.
        </p>
      </section>
      <section className="reviews-slider-cont">
        <ReviewsSlider />
      </section>
    </main>
  );
}
