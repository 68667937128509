import Anette from "../img/anette.jpg";
import cornerdotsorange from "../icons/corner-dots-orange.png";
import cornerdotswhite from "../icons/corner-dots-white.png";
import telemarketingicon from "../icons/telemarketing-static.png";
import bookingicon from "../icons/booking-static.png";
import marketingicon from "../icons/marketing-static.png";
import trainingicon from "../icons/training-static.png";
import contacticon from "../icons/contact-static.png";
import formicon from "../icons/form-static.png";
import { Link } from "react-router-dom";
import ReviewsSlider from "../data/ReviewsSlider";
import ContactForm from "../components/ContactForm";
import { HiOutlinePhone } from "@react-icons/all-files/hi/HiOutlinePhone";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import "../css/Forside.css";

export default function Forside() {
  return (
    <main className="main-forside">
      <section className="top-part-forside">
        <div className="forside-text-over-image-cont">
          <h1 className="title-forside orange-font">
            Telemarketingbureau - Mere end 20 års erfaring
          </h1>
          <p className="content-forside white-font text-over-img-forside">
            Mangler du hjælp til mødebooking, markedsanalyser eller leadgenerering?<br/> Så er du kommet til det helt rette
            sted.
          </p>
        </div>
      </section>
      <section className="forside-cont-grey-background">
        <div className="grid-display-2">
          <h1 className="title-forside darkblue-font">Tiltræk kunder</h1>
          <h2 className="title-forside sub-title-forside orange-font">
            til din virksomhed
          </h2>
          <p className="content-forside darkblue-font margin-section">
            I Provide Business har vi mere end 20 års erfaring med at øge vores
            kunders salgs- og marketingaktiviteter og frigive ressourcer til at
            pleje deres forretning. Med professionel markedsresearch bidrager vi
            derfor med den viden og erfaring, der skal til for at vende nye
            kunders opmærksomhed mod din virksomhed.
          </p>
          <p className="content-forside darkblue-font">
            I brancher med stigende konkurrence ser stadig flere virksomheder
            potentialet i at anvende nye værktøjer til at nå deres kunder.
          </p>
        </div>
        <div className="grid-display-1">
          <img
            src={Anette}
            className="image-anette-forside"
            alt="Anette Foss Jensen"
          ></img>
        </div>
      </section>
      <section className="card-forside-cont white-background">
        <p
          className="title-forside darkblue-font"
          id="text-under-image-forside"
        >
          Vi skaber værdi og vækst på <br /> bundlinjen{" "}
          <span className="orange-font">- hver gang!</span>
        </p>
      </section>
      <section className="card-forside-cont-boxshadow darkblue-bg">
        <img src={cornerdotsorange} className="forside-icon-1" alt="dots"></img>
        <img
          src={telemarketingicon}
          className="forside-icon-2"
          alt="telemarketing icon"
        ></img>
        <h1 className="title-forside white-font">Vores kompetencer</h1>
        <p className="content-forside white-font">
          Provide Business er et landsdækkende telemarketingbureau med speciale
          i mødebooking, markedsanalyser, leadgenerering og online
          administration. <br />
          <br />
          Læs mere her på vores hjemmeside, eller kontakt os på{" "}
          <a className="links-phone" href="tel:+4570345611">
            +45 70 34 56 11
          </a>
        </p>
        <button className="buttons-forside">
          <Link to="/kontakt" className="font-buttons-forside">
            Kontakt os
          </Link>
        </button>
        <img src={cornerdotswhite} className="forside-icon-3" alt="dots"></img>
      </section>
      <div className="desktop-layout-forside">
        <section className="card-forside-cont-boxshadow lightgray-bg card-desktop-grid">
          <img
            src={bookingicon}
            className="forside-icon-2 icon-desktop-grid"
            alt="mødebooking icon"
          ></img>
          <h1 className="title-forside darkblue-font">Mødebooking</h1>
          <p className="content-forside darkblue-font content-desktop-grid">
            Med Provide Business på sidelinjen som salgsafdelingens forlængede
            arm, kan du få fyldt kalenderen op med møder, en pipeline med varme
            leads og frigivet ressourcer i din virksomhed.
          </p>
        </section>
        <section
          className="card-forside-cont-boxshadow card-desktop-grid"
          id="z-index-2"
        >
          <img
            src={marketingicon}
            className="forside-icon-2 icon-desktop-grid"
            alt="marketing icon"
          ></img>
          <h1 className="title-forside darkblue-font">Marketing</h1>
          <p className="content-forside darkblue-font content-desktop-grid">
            Lad Provide Business stå for Lead-generering. Vi udarbejder en
            langsigtet strategi der tager højde for dine potentielle kunders
            købs-proces og de overvejelser de gør sig inden de køber.
          </p>
        </section>
        <section
          className="card-forside-cont-boxshadow orange-bg card-desktop-grid"
          id="z-index-1"
        >
          <img
            src={trainingicon}
            className="forside-icon-2 icon-desktop-grid"
            alt="training icon"
          ></img>
          <h1 className="title-forside darkblue-font">Salgstræning</h1>
          <p className="content-forside darkblue-font content-desktop-grid">
            Her hos Provide Business får alle telemarketing-medarbejdere
            salgstræning. Effektiv telemarketing kræver en unik række
            færdigheder, herunder evnen til at opbygge en god relation til
            kunderne over telefonen, identificere deres behov og afslutte
            opkaldet med en mødebooking.
          </p>
        </section>
      </div>
      <section
        className="card-forside-cont white-background"
        id="text-up-carousel-forside-cont"
      >
        <h1 className="title-forside darkblue-font">Vi gør en forskel</h1>
        <h2
          className="title-forside orange-font sub-title-forside"
          id="text-up-carousel-forside"
        >
          Hvad vores kunder siger om os
        </h2>
      </section>
      <section>
        <ReviewsSlider />
      </section>
      <section
        className="card-forside-cont white-background"
        id="under-carousel-section-forside"
      >
        <img
          src={contacticon}
          className="forside-icon-2"
          alt="Contact icon"
        ></img>
        <h1 className="title-forside darkblue-font">Kontakt os</h1>
        <h2 className="title-forside orange-font sub-title-forside">
          Og få et godt tilbud
        </h2>
        <p className="content-forside darkblue-font text-over-img-forside">
          Skal vi gøre en forskel for DIN virksomhed? Tag trygt kontakt til
          indehaver og mødebooker Anette Foss Jensen på:
        </p>
        <div className="cont-links-forside">
          <h2 className="phone-link-forside darkblue-font">
            <HiOutlinePhone />{" "}
            <a href="tel:+4540400184" className="phone-link-forside">
              +45 40 40 01 84
            </a>
          </h2>
          <h2 className="email-link-forside orange-font">
            <AiOutlineMail />
            <a
              href="mailto: afj@providebusiness.dk"
              className="email-link-forside"
            >
              {" "}
              afj@providebusiness.dk
            </a>
          </h2>
        </div>
      </section>
      <section className="card-forside-cont white-background">
        <img src={formicon} className="forside-icon-2" alt="Form icon"></img>
        <h1 className="title-forside darkblue-font">Bliv kontaktet</h1>
        <h2 className="title-forside orange-font sub-title-forside">
          Lige nu!
        </h2>
        <p className="content-forside darkblue-font text-over-img-forside">
          Passer det bedre at VI kontakter DIG? Udfyld formularen nedenunder, og
          vi kontakter dig snarest muligt!
        </p>
      </section>
      <div className="contactform-cont-forside">
        <ContactForm />
      </div>
    </main>
  );
}
