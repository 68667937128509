import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight";
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import { Link } from "react-router-dom";
import logo from "../img/logo-white.png";

const MenuItems = ({ items, depthLevel, onCloseDropdown }) => {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const closeDropdown = () => {
    setDropdown(false);
  };

  const handleLinkClick = (event) => {
    onCloseDropdown();
    event.preventDefault();
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      style={!items.image ? { padding: "0.7rem 1rem" } : {}}
    >
      {!items.url && items.submenu && (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
            {depthLevel > 0 ? (
              <MdKeyboardArrowRight className="arrow" />
            ) : (
              <MdKeyboardArrowDown className="arrow " />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            onCloseDropdown={closeDropdown}
          />
        </>
      )}
      {items.url && (
        <Link to={items.url} onClick={(event) => handleLinkClick(event)}>
          {items.image ? (
            <img src={logo} alt={items.title} className="logo-nav" />
          ) : (
            <span style={{ padding: "0.7 rem 1rem" }}>{items.title}</span>
          )}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
