import { Helmet, HelmetProvider } from "react-helmet-async";
import marketingicon from "../icons/marketing-static.png";

export default function Markedsanalyse() {
  return (
    <main className="main-desktop-lead top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Markedsanalyse</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead orange-bg desktop-layout-booking-cont higher-dropshadow">
        <img
          src={marketingicon}
          alt="marketing icon"
          className="marketing-icon"
        ></img>
        <h1 className="title lightgray-font titles-margin lightbolding">
          Markedsanalyse
        </h1>
        <p className="general-content lightgray-font line-height-content line-height-content">
          Skab indsigt i det marked du opererer på eller de markeder du ønsker
          at penetrere.
          <br />
          <br /> Kender du dine kunder? Ved du hvor glade de er for dit produkt
          – eller hvad de er utilfredse med? Kender du dine konkurrenter og
          deres agilitet? Kender du deres styrker og svagheder? <br />
          <br />
          Hvis ikke, kan en markedsanalyse hjælpe jer.
        </p>
      </section>
      <section className="card card-lead lightgray-bg secondlevel-dropshadow desktop-layout-booking-cont outcard-margin">
        <h1 className="title orange-font titles-margin">
          Hvad er markedsanalyse?
        </h1>
        <p
          className="general-content very-darkblue-font content-margin"
          id="desktop-display-content-seminar"
        >
          Oftest er virksomhederne ikke klar over hvilket potentiale de har for
          at ramme nye markeder eller segmenter, eller om hvorvidt deres
          nuværende kunder er tilfredse. Ved at få foretaget en grundig analyse,
          uanset hvilken form, kan I som virksomhed eksekvere bedre og mere
          målrettet.
          <br /> Det kræver mange ressourcer at indsamle nok data om sine kunder
          til at kunne evaluere ordentligt på dem. Det er vigtigt at man får
          reelle svar på de spørgsmål man har i virksomheden, og udformningen og
          eksekveringen af et interview eller en survey kan være tidskrævende.
          Ligeledes kan det kræve mange ressourcer at indsamle data og analysere
          efterfølgende. Mange virksomheder har ikke den slags ressourcer, eller
          også ønsker de at bruge de interne ressourcer på deres
          kernekompetencer. Det er her, Provide Business kommer ind i billedet.
        </p>
      </section>
      <section className="card card-lead darkblue-bg thirdlevel-dropshadow desktop-layout-booking-cont padding-card-marketing">
        <h1 className="title white-font">
          Provide Business kan bl.a. hjælpe jer med en dybdegående
          markedsanalyse, der omfatter:
        </h1>
        <section className="display-list-marketing list-marketing-desktop-right">
          <div className="list-marketing-desktop">
            <ol style={{ listStyleType: "decimal" }}>
              <li className="general-content white-font">
                <h1 className="list-title">Konkurrentanalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title">Brancheanalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title font">Kundeanalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title font">virksomhedsanalyse</h1>
                <br />
              </li>
            </ol>
          </div>
          <div className="list-marketing-desktop">
            <ol start="5" style={{ listStyleType: "decimal" }}>
              <li className="general-content white-font">
                <h1 className="list-title">Tilfredshedsanalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title">Forbrugeranalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title">Behovsanalyse</h1>
                <br />
              </li>
              <li className="general-content white-font">
                <h1 className="list-title">Kundetilfredshedsanalyse</h1>
                <br />
              </li>
            </ol>
          </div>
        </section>
        <p
          className="general-content lightgray-font content-margin"
          id="desktop-display-content-seminar"
        >
          I kan som virksomhed frit vælge hvilke analyser I mest har brug for,
          eller om I gerne vil have fuldt indblik i jeres marked. Provide
          Business vil vi gerne være jer behjælpelige i hvilket som helst step,
          uanset om det er hele processen eller dele af den.
        </p>
      </section>
    </main>
  );
}
