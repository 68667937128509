export const menuItems = [
  {
    title: "Om os",
    submenu: [
      {
        title: "Provide Business Modellen",
        url: "modellen",
      },
      {
        title: "Salgsteam",
        url: "salgsteam",
      },
      {
        title: "Karriere",
        url: "karriere",
      },
    ],
  },
  {
    title: "Vores services",
    submenu: [
      {
        title: "Telemarketing",
        submenu: [
          {
            title: "Mødebooking",
            url: "modebooking",
          },
          {
            title: "Leadgenerering",
            url: "leadgenerering",
          },
          {
            title: "Seminar",
            url: "seminar",
          },
        ],
      },
      {
        title: "Markedsanalyse",
        url: "markedsanalyse",
      },
      {
        title: "Marketing",
        url: "marketing",
      }
    ],
  },
  {
    title: "Provide Business logo",
    image: "./img/logo-white.png",
    url: "/",
    hiddenOnMobile: "true",
  },
  {
    title: "Vores cases",
    url: "vores-cases",
  },
  {
    title: "Kontakt",
    url: "kontakt",
  },
];
