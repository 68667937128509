import { useEffect, useState, useRef } from "react";
import { menuItems } from "../data/menuItems";
import SidebarItems from "./SidebarItems";
import MenuItems from "./MenuItems";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { AiOutlineMenu } from "@react-icons/all-files/ai/AiOutlineMenu";
import "../css/Sidebar.css";

const Navbar = () => {
  const [mobile, setMobile] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebar(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className="nav-container">
        {!mobile && (
          <ul className="menus">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              );
            })}
          </ul>
        )}
        {mobile && (
          <div className="sidebar-toggle">
            {sidebar ? (
              <AiOutlineClose
                className="sidebar-toggle-logo"
                onClick={(event) => {
                  event.stopPropagation();
                  setSidebar(!sidebar);
                }}
              />
            ) : (
              <AiOutlineMenu
                className="sidebar-toggle-logo"
                onClick={(event) => {
                  event.stopPropagation();
                  setSidebar(!sidebar);
                }}
              />
            )}
          </div>
        )}
      </nav>
      <div className={sidebar ? "sidebar active" : "sidebar"} ref={sidebarRef}>
        <ul className="menus-sidebar">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <SidebarItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                setSidebar={setSidebar}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
