import seminaricon from "../icons/conference-static.png";
import meetingimg from "../img/meeting-office.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Seminar() {
  return (
    <main className="main-desktop-lead top-content-margin">
            <HelmetProvider>
        <Helmet>
          <title>Provide Business | Seminar</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead darkblue-bg higher-dropshadow">
        <img
          src={seminaricon}
          className="seminar-icon"
          alt="seminar icon"
        ></img>
        <h1 className="title white-font titles-margin lightbolding">
          Seminar og webinar <br />
          opfølgning
        </h1>
        <p className="general-content white-font content-top-margin">
          At invitere til et seminar, webinar eller firmaevent kan være en
          vigtig brik i din salgsstrategi for at komme tættere på dine kunder.
        </p>
      </section>
      <section className="card card-lead lightgray-bg secondlevel-dropshadow outcard-margin">
        <h1 className="title orange-font titles-margin">Undgå af aflyse</h1>
        <p className="general-content very-darkblue-font content-margin" id="desktop-display-content-seminar">
          Hos Provide Business tilrettelægger vi en telemarketingkampagne ud fra
          dine ønsker og behov.
          <br />
          <br />
          Provide Business hjælper dig til at skabe interesse og øge
          deltagerraten på dine seminar-/webinar. Igennem telemarketing
          kontakter vi dine nye kunder skaber opmærksomhed omkring dine
          seminar-/webinar og virksomhed.
          <br />
          <br />
          Vis Anette Foss Jensens profilProvide Business har fingeren på pulsen
          og har dagligt kontakt til markedet. Vi opsamler løbende viden i vore
          brancheteams.
          <br />
          <br />
          Det kan være tidskrævende at kontakte sin kundeportefølje eller nye
          leads, men den opgave tager vi gerne på vores skuldre og løfter
          opgaven.
          <br />
          <br />
          Vellykket telemarketing efterlader kunden med en oplevelse af at have
          opnået en fordel eller at blive stillet over for en spændende mulighed
          – og det give
        </p>
      </section>
      <section className="desktop-layout-seminar">
        <section className="thirdlevel-dropshadow img-cont-seminar">
          <img
            src={meetingimg}
            alt="office seminar"
            className="img-seminar"
          ></img>
        </section>
        <section className="card card-lead orange-bg lower-dropshadow content-seminar-desktop">
          <h1 className="title darkblue-font titles-margin">
            Erfaring og succes går <br />
            hånd i hånd
          </h1>
          <p className="general-content very-darkblue-font">
            Dine nye kunder skal fra første samtale føle sig godt tilpas og
            tænke godt om dig og din virksomhed. Vi kontakter dine kunder med
            professionalisme og et smil. Vi vil altid være loyale overfor din
            virksomhed. <br />
            <br />
            Vi går aldrig på kompromis med kvaliteten og ønsker jer og jeres
            virksomhed den største succes. <br />
            <br />
            Alle kan løfte røret og taste et nummer, men det er en kunst at
            formidle budskabet på en ordenligt og interessant måde, så
            modtageren rent faktisk sidder tilbage med en oprigtig interesse.{" "}
          </p>
        </section>
      </section>
    </main>
  );
}
