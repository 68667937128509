import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import logowhite from "../img/logo-white.png";

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <Navbar />
        <Link to="/" className="logo-cont">
          <img src={logowhite} alt="Provide Business" className="logo"></img>
        </Link>
      </div>
    </header>
  );
};

export default Header;
