import { Routes, Route } from "react-router-dom";
import "../css/App.css";
import "../css/Nav.css";
import "../css/Footer.css";
import Forside from "../pages/Forside";
import PBModellen from "../pages/PBModellen";
import Salgsteam from "../pages/Salgsteam";
import Karriere from "../pages/Karriere";
import Leadgenerering from "../pages/Leadgenerering";
import Seminar from "../pages/Seminar";
import VoresCases from "../pages/VoresCases";
import Kontakt from "../pages/Kontakt";
import Privatlivspolitik from "../pages/Privatlivspolitik";
import Layout from "./Layout";
import Mødebooking from "../pages/Modebooking";
import Marketing from "../pages/Marketing";
import Markedsanalyse from "../pages/Markedsanalyse";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Forside />} />
          <Route path="modellen" element={<PBModellen />} />
          <Route path="salgsteam" element={<Salgsteam />} />
          <Route path="karriere" element={<Karriere />} />
          <Route path="modebooking" element={<Mødebooking />} />
          <Route path="leadgenerering" element={<Leadgenerering />} />
          <Route path="seminar" element={<Seminar />} />
          <Route path="marketing" element={<Marketing />} />
          <Route path="markedsanalyse" element={<Markedsanalyse />} />
          <Route path="vores-cases" element={<VoresCases />} />
          <Route path="kontakt" element={<Kontakt />} />
          <Route path="privatlivspolitik" element={<Privatlivspolitik />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
