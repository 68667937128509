import logo from "../img/logo-white.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <main className="footer-cont-total">
      <section className="footer-cont-top">
        <div className="footer-first-part">
          <div className="semi-circle-footer">
            <Link to="/">
              <img
                src={logo}
                alt="Provide Business Logo"
                className="logo-footer"
              />
            </Link>
          </div>
          <div className="text-logo-footer-cont">
            <p className="text-logo-footer">
              Vi er et landsdækkende bureau med flere års erfaring, der altid
              sikrer dig telemarketing af højeste kvalitet.
            </p>
          </div>
        </div>
        <div className="footer-cont-infos">
          <div className="content-cont-footer">
            <h1 className="titles-footer">Kontakt os</h1>
            <a
              className="links-footer text-footer"
              href="mailto:info@providebusiness.dk"
            >
              info@providebusiness.dk
            </a>
            <br />
            <a className="links-footer text-footer" href="tel:+4570345611">
              +45 70 34 56 11
            </a>
          </div>
          <div className="content-cont-footer">
            <h1 className="titles-footer">Find os</h1>
            <span className="text-footer">Samsøvej 22</span>
            <br />
            <span className="text-footer">8382 Hinnerup</span>
          </div>
          <div className="content-cont-footer">
            <h1 className="titles-footer">Information</h1>
            <Link to="/privatlivspolitik" className="text-footer links-footer">
              Privatlivspolitik
            </Link>
            <br />
            <span className="text-footer">CVR: 42093238</span>
          </div>
          <div className="content-cont-footer">
            <h1 className="titles-footer">Social Media</h1>
            <div className="footer-some">
              <a
                className="links-footer some-mobile"
                href="https://www.facebook.com/providebusiness"
              >
                <FacebookIcon sx={{ fontSize: "2rem" }} />
              </a>
              <a
                className="links-footer some-desktop"
                href="https://www.facebook.com/providebusiness"
              >
                <FacebookIcon sx={{ fontSize: "3rem" }} />
              </a>
              <a
                className="links-footer some-mobile"
                href="https://www.linkedin.com/company/provide-business/"
              >
                <LinkedInIcon sx={{ fontSize: "2rem" }} />
              </a>
              <a
                className="links-footer some-desktop"
                href="https://www.linkedin.com/company/provide-business/"
              >
                <LinkedInIcon sx={{ fontSize: "3rem" }} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-cont-bot">
        <h1>© Alle rettigheder forbeholdes Provide Business – 2023</h1>
      </section>
    </main>
  );
}
