import Anette from "../img/anette.jpg";
import Mia from "../img/mia.jpg";
import Stine from "../img/stine.jpg";

export default function SalgsteamData() {
  return (
    <main className="main-salgsteam-data">
      <section className="person-cont-team">
        <section className="picture-team-cont">
          <img
            src={Anette}
            alt="Anette Foss Jensen"
            className="picture-team"
          ></img>
        </section>
        <section className="content-team-cont">
          <h1 className="title darkblue-font bolding margin-bottom-title">
            Anette Foss Jensen
          </h1>
          <h2 className="subtitle orange-font lightbolding">
            Indehaver & Mødebooker
          </h2>
          <p className="general-content very-darkblue-font margin-top-content">
            Anette har 22 års erfaring med telemarketing og har opbygget en
            enorm stor viden og netværk. Hun besidder stærke kommunikative
            evner, som er et vigtigt fundament i branchen.
            <br />
            <br />
            Anette har gennem sin arbejdserfaring hjulpet adskillige
            virksomheder med opgaver inden for telemarketing i stort set alle
            brancher. <br />
            <br />
            Anette vægter troværdighed og professionalisme rigtig højt, og det
            afspejler sig i måden hun går til opgaverne på.
          </p>
        </section>
      </section>
      <section className="person-cont-team">
        <section className="picture-team-cont">
          <img src={Mia} alt="Mia" className="picture-team"></img>
        </section>
        <section className="content-team-cont">
          <h1 className="title darkblue-font bolding margin-bottom-title">
            Mia
          </h1>
          <h2 className="subtitle orange-font lightbolding">
            Økonomi, Marketing & Administration
          </h2>
          <p className="general-content very-darkblue-font margin-top-content">
            Mia er uddannet markedsføringsøkonom, og har en bachelor i
            erhvervsøkonomi og en kandidat i marketing.
            <br />
            <br />
            Mia er en del af ledelsen, og sidder til dagligt med administrative
            opgaver. Hun har flere års erfaring med marketing og økonomistyring,
            og er god til at holde overblikket over de mange projekter vi har i
            virksomheden.
          </p>
        </section>
      </section>
      <section className="person-cont-team">
        <section className="picture-team-cont">
          <img src={Stine} alt="Stine" className="picture-team"></img>
        </section>
        <section className="content-team-cont">
          <h1 className="title darkblue-font bolding margin-bottom-title">
            Stine
          </h1>
          <h2 className="subtitle orange-font lightbolding">
            Mødebooker & Teamleder
          </h2>
          <p className="general-content very-darkblue-font margin-top-content">
            Stine er en dygtig og engageret mødebooker med mange års erfaring
            indenfor salgsbranchen.
            <br />
            <br />
            Stine kommer fra en stilling i mediebranchen og er uddannet
            serviceøkonom.
            <br />
            <br />
            Stine har mere end 5 års erfaring med mødebooking og har en
            fantastisk evne til at omstille sig på hver enkelt kunde.
          </p>
        </section>
      </section>
    </main>
  );
}
