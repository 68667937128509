import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function SidebarItems({ items, setSidebar }) {
  const [open, setOpen] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".sidebar")) {
        setOpen({});
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  if (items.hiddenOnMobile) {
    return null;
  }

  if (items.submenu) {
    return (
      <div className={open[items.id] ? "sidebar-item open" : "sidebar-item"}>
        <div
          className="sidebar-title"
          onClick={() => setOpen({ ...open, [items.id]: !open[items.id] })}
        >
          <span>{items.title}</span>
          <MdKeyboardArrowDown
            className="toggle-btn"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="sidebar-content">
          {items.submenu.map((child, index) => (
            <SidebarItems key={index} items={child} setSidebar={setSidebar} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Link
        to={items.url || "#"}
        className="sidebar-item plain"
        onClick={() => setSidebar(false)}
      >
        {items.title}
      </Link>
    );
  }
}
