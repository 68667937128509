import icon1 from "../icons/contact-static.png";
import meetingoffice from "../img/meeting-office.jpg";
import icon2 from "../icons/corner-line-orange.png";
import icon3 from "../icons/form-white-static.png";
import icon4 from "../icons/corner-line-white.png";
import "../css/Kontakt.css";
import { HiOutlinePhone } from "@react-icons/all-files/hi/HiOutlinePhone";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import ContactForm from "../components/ContactForm";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Kontakt() {
  return (
    <main className="main-kontakt">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Kontakt</title>
        </Helmet>
      </HelmetProvider>
      <section className="first-card-kontakt-cont lightgray-bg">
        <div className="circle-bg"></div>
        <div className="first-part-first-card-kontakt">
          <img src={icon1} alt="contact-icon" className="icon1-kontakt" />
          <h1 className="title-kontakt darkblue-font">
            Har du brug for vores hjælp
          </h1>
          <h2 className="sub-title-kontakt orange-font">
            til din telemarketing?
          </h2>
          <p className="content-kontakt darkblue-font">
            Vi står altid klar til at svare på spørgsmål om alt inden for
            telemarketing. Har du spørgsmål til mødebooking, webshops,
            leadgenerering eller noget helt fjerde? Så tag endelig kontakt til
            os allerede i dag – vi svarer hurtigst muligt.
          </p>
          <div className="cont-links-forside links-kontakt">
            <h2 className="phone-link-forside phone-kontakt">
              <HiOutlinePhone />{" "}
              <a
                href="tel:+4540400184"
                className="phone-link-forside phone-kontakt"
              >
                +45 40 40 01 84
              </a>
            </h2>
            <h2 className="email-link-forside email-kontakt">
              <AiOutlineMail />
              <a
                href="mailto: afj@providebusiness.dk"
                className="email-link-forside email-kontakt"
              >
                {" "}
                afj@providebusiness.dk
              </a>
            </h2>
          </div>
        </div>
        <div className="second-part-first-card-kontakt">
          <img
            src={meetingoffice}
            className="office-image-kontakt"
            alt="Office meeting Provide Business"
          />
        </div>
      </section>
      <section className="display-desktop">
        <section className="second-card-kontakt-cont darkblue-bg">
          <img src={icon2} className="forside-icon-1" alt="icon corner lines" />
          <img src={icon3} className="kontakt-form-icon" alt="form icon"></img>
          <h1 className="title-kontakt white-font">Vi svarer inden for</h1>
          <h2 className="sub-title-kontakt orange-font">24 timer</h2>
          <p className="content-kontakt white-font">
            Hvis du har spørgsmål, kan du udfylde kontaktformularen herunder. Vi
            vender tilbage hurtigst muligt.
          </p>
          <div className="form-kontakt-cont">
            <ContactForm />
          </div>
          <div className="circle-bg-second"></div>
        </section>
        <section className="third-card-kontakt-cont orange-bg">
          <h1 className="title-kontakt darkblue-font">Åbningstider</h1>
          <p className="content-kontakt darkblue-font">
            Hverdage: 8:30 - 15:00
          </p>
          <div className="info-kontakt-cont">
            <h1 className="title-kontakt darkblue-font">Find os her</h1>
            <p className="content-kontakt darkblue-font">
              Samsøvej 22, <br />
              8382 Hinnerup
            </p>
          </div>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2216.6262647886624!2d10.069099216050228!3d56.249965961004825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c16acd865f0c3%3A0x16baea52ce11f420!2sSams%C3%B8vej%2022%2C%208382%20Hinnerup!5e0!3m2!1sen!2sdk!4v1679479485201!5m2!1sen!2sdk"
                title="map"
                loading="lazy"
                className="googlemap"
              ></iframe>
            </div>
          </div>
          <img src={icon4} className="forside-icon-3" alt="icon corner lines" />
        </section>
      </section>
    </main>
  );
}
