import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SanderGruppen from "../img/sandergruppen.png";
import ADK from "../img/adkteknik.png";
import KoldOgSort from "../img/koldogsort.png";
import ASE from "../img/ase.png";
import Rhetorica from "../img/rhetorica.png";
import Siteguard from "../img/siteguard.png";
import Imploy from "../img/imploy.png";
import AdAgenzy from "../img/adagenzy.png";
import NRGI from "../img/nrgi.png";
import tornvig from "../img/tornvig.png";
import "../css/Slider.css";
/*You can add new import of images here up to this line*/

export default function ReviewsSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    adaptiveHeight: true,
  };
  /* If you want to add a new client, scroll all the way down, I have written at the end where you can add it */
  return (
    <Slider {...settings} className="review-container">
      {/*The client bloc is beginning after this line*/}
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://sandergruppen.dk/">
            <div className="logo-cont-display">
              <img
                src={SanderGruppen}
                alt="Sander Gruppen Logo"
                className="review-logo"
              ></img>
            </div>
          </a>
          {/*The above lines is where you should change the logo of the company, change the "src" attribute and the "alt" attribute*/}
        </div>
        <div>
          {/*The following parts are the text blocs. You can change anything that is written in white, it's what the users are seeing on the website*/}
          <h1 className="review-company-name">Sander Gruppen</h1>
          <p className="review-content">
            “Vi bevæger os i en smal niche, og manglede et ekstra ben at støtte
            på, i forhold til at få mødekalenderen fyldt op.
            <br />
            <br />
            Anette Jensen fra Provide Business, har siden starten af vort
            samarbejde for 1½ år siden, leveret kvalitets arbejde. Ikke nok med
            at møderne står i kø, så er kvaliteten af møderne- og ikke mindst
            Anettes måde at profilere Sander Gruppen på, helt i top.
            <br />
            <br />
            Anette og Provide Business, vil fremover være en fast del af Sander
            Gruppens strategi, for hvervning af gamle, såvel som nye
            bekendtskaber.
            <br />
            <br />
            En virksomhed og person, man trygt kan overlade et stort ansvar
            til.”
          </p>
          <h2 className="review-person-name">Julie Stilling Skyum</h2>
          <p className="review-person-title">Projektleder, Sander Gruppen</p>
        </div>
      </div>
      {/*This is the end of a client bloc*/}
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://www.adkteknik.dk/">
            <div className="logo-cont-display">
              <img src={ADK} alt="ADK Logo" className="review-logo"></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">ADK Teknik</h1>
          <p className="review-content">
            “ADK Teknik & Låse ApS, har de sidste 3 måneder, benytte sig af
            Anette Foss Jensens gode evner til at booke salgsmøder.
            <br />
            <br />
            Vi har i samarbejdet, haft fokus på alarm, kameraovervågning,
            adgangskontrol og låsesystemer og har ud fra Anettes rådgivning,
            opnået gode resultater.”
          </p>
          <h2 className="review-person-name">Jim Frederiksen</h2>
          <p className="review-person-title">ADK Teknik og Låse ApS</p>
        </div>
      </div>
      <div className="review-bloc" id="koldogsort-background">
        <div className="review-logo-cont">
          <img
            src={KoldOgSort}
            alt="Kold & Sort Logo"
            className="review-logo"
          ></img>
        </div>
        <div>
          <h1 className="review-company-name">Kold & Sort</h1>
          <p className="review-content">
            “Vi har benyttet os af Provide Business ekspertise til at få solgt
            billetter til Danmarks Største Open Air Dance Lounge Bar, Århus Havn
            – og samarbejdet fortsætter. Vi anbefaler klart deres ekspertise i
            vores netværk.”
          </p>
          <h2 className="review-person-name">Thomas Kold</h2>
          <p className="review-person-title">Ejer, Kold & Sort Live</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://www.ase.dk/">
            <div className="logo-cont-display">
              <img src={ASE} alt="ASE Logo" className="review-logo"></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">ASE</h1>
          <p className="review-content">
            “I Ase har vi haft et godt og udviklende samarbejde med
            ProvideBusiness ved Anette Foss Jensen. Vi har været glad for det
            høje faglige niveau, som Anette har udvist. Ligeledes har vi værdsat
            den gode og grundige sparring omkring håndtering af BtB leads samt
            sparring til hvordan man øger sin succesrate med mødebooking i B2B
            segmentet.
            <br />
            <br />
            Provide Business ved Anette Foss Jensen er en god og troværdig
            samarbejdspartner, når der skal bookes møder.”
          </p>
          <h2 className="review-person-name">Steffan Adelhardt</h2>
          <p className="review-person-title">Digital Lead Manager, ASE</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://rhetorica.dk/">
            <div className="logo-cont-display">
              <img
                src={Rhetorica}
                alt="Rhetorica Logo"
                className="review-logo"
              ></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">Rhetorica</h1>
          <p className="review-content">
            “I Rhetorica er vi verdensmestre i at uddanne ledere og specialister
            i at komme ud over rampen med deres kommunikation. Og vi er
            superskarpe til at hjælpe virksomheder med at sætte ord på deres
            strategi, og få ordene at leve ude i virkeligheden. Høj faglighed og
            kvalitet er altafgørende og bærende i alt hvad vi gør.
            <br />
            <br />
            Og det er præcis derfor vi er lykkelige over, at der findes en
            virksomhed som Provide Business. Her er de eksperter i alt det, som
            vi synes er svært; det strategiske salgsarbejde og opkaldet til
            potentielle kunder og samarbejdspartnere. Når du nu ikke selv er den
            bedste, eller den mest tålmodige – så er det en fornøjelse at opleve
            den kvalitet, loyalitet og målrettethed man oplever hos Provide
            Business”
          </p>
          <h2 className="review-person-name">Kim Ege Møller</h2>
          <p className="review-person-title">Direktør, Rhetorica ApS</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://site-guard.dk/">
            <div className="logo-cont-display">
              <img
                src={Siteguard}
                alt="Site Guard Logo"
                className="review-logo"
              ></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">Site Guard</h1>
          <p className="review-content">
            “Jeg har i mere end 10 år haft fornøjelsen af et samarbejde med
            Anette Foss Jensen, og er selvfølgelig fulgt med over i hendes
            seneste konstellation Provide Business. Anette har specialiseret sig
            indenfor vagt & sikkerhedsbranchen, hvor hun med sine gode
            kommunikative evner har booket adskillige kvalitetsmøder med nogle
            af landets største virksomheder. Uanset opgavens størrelse har
            Anette altid taget opgaven til sig og leveret flotte og brugbare
            resultater til Site Guard samt mine tidligere virksomheder. Jeg vil
            til enhver tid anbefale Provide Business! Ovenstående kan uddybes
            yderligere på tlf. 51 21 95 60.”
          </p>
          <h2 className="review-person-name">Bo Larsen</h2>
          <p className="review-person-title">Direktør, Site Guard</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://imploy.dk/">
            <div className="logo-cont-display">
              <img src={Imploy} alt="Imploy Logo" className="review-logo"></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">Imploy</h1>
          <p className="review-content">
            “Vi har benyttet Provide Business til at hjælpe os med mødebooking i
            forbindelse med vores salgsstrategi. På trods af at Imploy befinder
            sig i en af de hårdeste brancher, kunne Provide Business booke mange
            kundemøder indenfor den første prøveperiode. Det viser os, at de har
            den rette tilgang til opgaverne, samt dygtige medarbejdere med gode
            salgsevner.
            <br />
            <br />
            Derfor kan jeg stærkt anbefale Provide Business, hvis I skal have
            hjælp til at finde jeres kommende nye kunder."
          </p>
          <h2 className="review-person-name">Vicram Channi</h2>
          <p className="review-person-title">CEO, Site Guard</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://adagenzy.dk/">
            <div className="logo-cont-display">
              <img
                src={AdAgenzy}
                alt="AD Agenzy Logo"
                className="review-logo"
              ></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">AD Agenzy</h1>
          <p className="review-content">
            “Vi har brugt Provide Business til mødebooking, hvilket de har gjort
            rigtig godt. Der er kommet et stabilt flow af kvalificerede møder,
            med gode informationer om, hvad de har talt med et lead om. <br />
            <br />
            Bestemt anbefalet til B2B mødebooking, selv i konkurrenceprægede
            nicher.”
          </p>
          <h2 className="review-person-name">Kenneth Vilster</h2>
          <p className="review-person-title">Ejer, AD Agenzy</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://nrgi.dk/">
            <div className="logo-cont-display">
              <img src={NRGI} alt="NRGI Logo" className="review-logo"></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">NRGI</h1>
          <p className="review-content">
            “NRGi har igennem en årrække benyttet Provide Business/Anette Foss
            ifm. mødebooking til vores kørende sælgere. Et samarbejde vi er
            meget tilfredse med og som igennem tiden har udviklet sig. Anette og
            hendes medarbejdere arbejder samvittighedsfuldt og professionelt med
            vores opgave og kommer med de varmeste anbefalinger herfra!"
          </p>
          <h2 className="review-person-name">Christian Wulff</h2>
          <p className="review-person-title">Head of sales, NRGI</p>
        </div>
      </div>
      <div className="review-bloc">
        <div className="review-logo-cont">
          <a href="https://tornvigmaskiner.dk/">
            <div className="logo-cont-display">
              <img src={tornvig} alt="NRGI Logo" className="review-logo"></img>
            </div>
          </a>
        </div>
        <div>
          <h1 className="review-company-name">Tornvig Christensen Maskiner</h1>
          <p className="review-content">
            “Vi valgte i Tornvig Christensen maskiner at outsource vores møde
            bookinger efter en år række at have haft det In-House.
            <br />
            <br /> Ved en tilfældighed blev vi henvist til Provide business,
            hvilket vi ikke har fortrudt et sekund.
            <br /> Vi føler os godt rustet og har fra starten i august 2022 følt
            en ro i maven ved at ligge bookingerne over til Anette og hendes
            gode og prof medarbejdere. Vi har en tæt dialog med Provide, de er
            omstillings parate når vi har haft nye procedurer i hvordan vi
            skulle gribe tingene an, og Anette er super god at at følge op på
            ting hvis noget skal finjusteres mm.
            <br />
            <br /> Kan varmt anbefale Provide.”
          </p>
          <h2 className="review-person-name">Søren Bjerggaard</h2>
          <p className="review-person-title">
            Salgschef og medejer, Tornvig Christensen Maskiner A/S
          </p>
        </div>
      </div>
      {/*If you want to add a new client, you can paste the bloc under this line. Think about copying it from <div className="review-bloc"> to the corresponding end </div> tag*/}
    </Slider>
  );
}
