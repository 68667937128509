export default function Privatlivspolitik() {

    return(
        <main className='main-layout-privatlivspolitik'>
            <div className='main-title-politik'>
                <h1>Cookies & <br/>Privatlivspolitik</h1>
            </div>
            <section className='main-section-politik'>
                <div>
                    <h2 className='second-title-politik'>Cookie - og privatlivspolitik hos Provide Business ApS</h2>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Introduktion</h3>
                    <p className='content-politik'>Når du besøger vores website, indsamles der oplysninger om dig, som bruges til at tilpasse og forbedre vores indhold og til at øge værdien af de annoncer, der vises på siden. Hvis du ikke ønsker, at der indsamles oplysninger, bør du slette dine cookies <a className="link-politik" href="https://minecookies.org/cookiehandtering/">(se vejledning)</a> og undlade videre brug af websitet. Nedenfor har vi uddybet, hvilke informationer der indsamles og deres formål.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Cookies</h3>
                    <p className='content-politik'>Websitet anvender “cookies”, der er en tekstfil, som gemmes på din computer, mobil el. tilsvarende med det formål at genkende den, huske indstillinger, udføre statistik og målrette annoncer. Cookies kan ikke indeholde skadelig kode som f.eks. virus.<br/>
                    Det er muligt at slette eller blokere for cookies. Se vejledning <a className="link-politik" href="https://minecookies.org/cookiehandtering/">her.</a><br/>
                    Hvis du sletter eller blokerer cookies, vil annoncer kunne blive mindre relevante for dig og optræde hyppigere. Du kan desuden risikere at websitet ikke fungerer optimalt samt at der er indhold, du ikke kan få adgang til.</p>
                </div>
                <div>
                    <h2 className='second-title-politik'>Personoplysninger</h2>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Generelt</h3>
                    <p className='content-politik'>Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig. Når du benytter vores website, indsamler og behandler vi en række sådanne informationer. Det sker f.eks. ved alm. tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev, deltager i konkurrencer eller undersøgelser, registrerer dig som bruger eller abonnent og øvrig brug af services.<br/>
                    Vi indsamler og behandler typisk følgende typer af oplysninger: Et unikt ID og tekniske oplysninger om din computer, tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke sider du klikker på (interesser). I det omfang du selv giver eksplicit samtykke hertil og selv indtaster informationerne behandles desuden: Navn, telefonnummer, e-mail og adresse. Det vil typisk være hvis du beder os om at kontakte dig.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Sikkerhed</h3>
                    <p className='content-politik'>Vi behandler dine personoplysninger sikkert og fortroligt i overensstemmelse med gældende lovgivning, herunder persondataforordningen og databeskyttelsesloven.<br/>
                    Dine oplysninger vil alene blive anvendt til det formål, de er indsamlet til, og vil blive slettet, når dette formål er opfyldt eller ikke længere relevant.<br/>
                    Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller ulovligt bliver slettet, offentliggjort, fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Formål</h3>
                    <p className='content-politik'>Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have størst sandsynlighed for at være relevante for dig samt at kunne levere de services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev. Herudover anvender vi oplysningerne til at optimere vores services og indhold.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Periode af opbevaring</h3>
                    <p className='content-politik'>Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter dem, når de ikke længere er nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden for opbevaring. Det er derfor ikke muligt at angive en generel tidsramme for, hvornår informationer slettes.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Videregivelse af oplysninger</h3>
                    <p className='content-politik'>Data om din brug af websitet, hvilke annoncer, du modtager og evt. klikker på, geografisk placering, køn og alderssegment m.v. videregives til tredjeparter i det omfang disse oplysninger er kendt. Markedsføringscookies er ofte tredjepartscookies. Oplysningerne anvendes til målretning af annoncering.<br/>
                    Vi benytter herudover en række tredjeparter til opbevaring og behandling af data. Disse behandler udelukkende oplysninger på vores vegne og må ikke anvende dem til egne formål.<br/>
                    Videregivelse af personoplysninger som navn og e-mail m.v. vil kun ske, hvis du giver samtykke til det. Vi anvender kun data behandlere i EU.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Indsigt og klager</h3>
                    <p className='content-politik'>Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig i et almindeligt format (dataportabilitet). Du kan desuden til enhver tid gøre indsigelse mod, at oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at der bliver behandlet oplysninger om dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret til at de bliver rettet eller slettet. Henvendelse herom kan ske til: afj@providebusiness.dk. Hvis du vil klage over vores behandling af dine personoplysninger, har du også mulighed for at tage kontakt til Datatilsynet.<br/>
                    Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen af dine personoplysninger, kan du også sende os en anmodning herom til ovennævnte e-mailadresse.</p>
                </div>
                <div>
                    <h3 className='sub-title-politik'>Udgiver</h3>
                    <p className='content-politik'>Websitet ejes og publiceres af:<br/>
                        Provide Business ApS<br/>
                        Samsøvej 22<br/>
                        8382 Hinnerup<br/>
                        <a className='link-politik' href='mailto:afj@providebusiness.dk'>Email: afj@providebusiness.dk</a><br/>
                        <a className='link-politik' href='tel:+4570345611'>Telefon: +45 70 34 56 11</a>
                    </p>
                </div>
            </section>
        </main>
    )
} 