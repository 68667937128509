import { Helmet, HelmetProvider } from "react-helmet-async";

export default function PBModellen() {
  return (
    <main className="main-desktop-lead top-content-margin">
      <HelmetProvider>
        <Helmet>
          <title>Provide Business | Modellen</title>
        </Helmet>
      </HelmetProvider>
      <section className="card card-lead orange-bg higher-dropshadow desktop-layout-booking-cont">
        <h1 className="title darkblue-font titles-margin">
          Provide Business Modellen
        </h1>
        <p
          className="general-content darkblue-font content-margin line-height-content"
          id="desktop-display-content-seminar"
        >
          Vi tager hånd om dine behov, er lydhør og har en oprigtig interesse i
          dig og din virksomhed. Vi tager vores personlighed med på arbejde,
          hvilket gør, at kunden får en oplevelse af en personlig og
          professionel opgaveløsning.
        </p>
      </section>
      <section className="card card-lead lightgray-bg secondlevel-dropshadow desktop-layout-booking-cont">
        <h1 className="title orange-font titles-margin">
          Lad os skaffe dig nye kunder
        </h1>
        <p
          className="general-content very-darkblue-font content-margin line-height-content"
          id="desktop-display-content-seminar"
        >
          Leadgenerering refererer til processen med at identificere og skaffe
          potentielle kunder eller "leads" gennem udgående opkald til en
          målrettet liste over potentielle kunder, og er absolut den mest
          resultatskabende telemarketingaktivitet der findes. Formålet med
          leadgenerering er at øge antallet af kvalificerede kundeemner, som
          virksomheder kan kontakte og sælge til. <br />
          Leadgenerering hos Provide Business kan hjælpe virksomheder med at
          skaffe nye kunder ved at give dem adgang til en større pool af
          potentielle kunder, end de ville have haft adgang til på egen hånd.
          Fordi vi bruger en gennemprøvet og målrettet tilgang til
          leadgenerering, kan vores kunder målrette de af deres kunder, der er
          mest tilbøjelige til at have interesse i deres produkter eller
          tjenester. Dette kan resultere i en højere konverteringsrate, da de
          kunder, der er blevet identificeret som kvalificerede af Provide
          Business, er mere tilbøjelige til at købe.
        </p>
      </section>
      <section className="card card-lead darkblue-bg thirdlevel-dropshadow desktop-layout-booking-cont">
        <h1 className="title lightgray-font titles-margin">
          Kvalificering af leads
        </h1>
        <p
          className="general-content lightgray-font content-margin line-height-content"
          id="desktop-display-content-seminar"
        >
          Kvalificering af leads indebærer at identificere, hvilke af de
          indsamlede leads der er mest sandsynlige til at købe virksomhedens
          produkter eller tjenester. Kvalificering kan omfatte at indsamle
          yderligere oplysninger om leads, såsom deres branche, budget, behov,
          beslutningstagere og købsproces. Når en lead er blevet kvalificeret,
          kan salgsteamet fokusere på at konvertere disse leads til betalende
          kunder.
        </p>
      </section>
      <section className="card card-lead orange-bg lower-dropshadow desktop-layout-booking-cont">
        <h1 className="title very-darkblue-font titles-margin">
          Kunder på både kort og lang sigt
        </h1>
        <p
          className="general-content very-darkblue-font content-margin line-height-content"
          id="desktop-display-content-seminar"
        >
          Leadgenerering har hjulpet vores kunder med at opbygge en liste over
          potentielle kunder, som de kan fortsætte med at markedsføre til og
          udvikle relationer med på lang sigt. Ved at lade Provide Business
          holde kontakten med potentielle kunder og sende dem relevant
          information og tilbud via mail eller genopfølgende opkald, kan vores
          kunder bibevare et forhold og øge sandsynligheden for at få deres
          forretning på et senere tidspunkt. <br />I alt kan leadgenerering være
          en effektiv måde for virksomheder at skaffe nye kunder og øge deres
          omsætning. Det kræver dog en effektiv planlægning, ekspertise og
          ressourcer for at opnå succes, og her har Provide Business mere end 20
          års erfaring.
        </p>
      </section>
    </main>
  );
}
